import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {MissionSetForm, MissionSetStoreStateProps} from "@/models";


export const useMissionStore = defineStore("mission", {
    state: (): MissionSetStoreStateProps => ({
        items: [],

        originItems: [],

        create_items: [],
        delete_ids: [],
        update_items: [],
        updateFlag: false,
        pinId: 0,

        query: {
            quarter: 1
        },
        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 1,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        currentLocation: {
            lat: null,
            lng: null,
        },

        missionQuestionItems: [],
        missionRewardOptions: [],
        setting_step: 0,
        quarterLength: 0,
        game_name: '',


        map: null,
        advanceMarkerElement: null,
        currentMarker: null,

        //  마커에 등록되는 데이터 정보 , 한번씩 싹 날려줘야할 필요성이 있어서 items 로 사용하지 않음
        onlyCreateGameId: null,

        // 지도에 표현되는 마커 dom 자체의 정보
        markerDomList: []
    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                game_mission_id: null,
                game_id: null,
                question_id: null,
                reward_id: null,
                order: 0,
                latitude: 0,
                longitude: 0,
                pinId: 0,
                quarter_no: 0,
                updateFlag: false
            };
            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },
        resetItems() {

        },

        onGet(value: number) {
            this.form = cloneDeep(this.items[Number(this.query.quarter) - 1].items.find((ele: MissionSetForm) => ele.pinId === value))
        },
        changeMissionSelectItems() {

            let questionId: number[] = []
            let rewardId: number[] = []

            this.items.forEach((ele: any) => {
                ele.items.forEach((val: any) => {
                    questionId = [...questionId, val.question_id]
                    rewardId = [...rewardId, val.reward_id]
                })
            })

            this.missionQuestionItems = [...this.missionQuestionItems.map((ele) => {
                if (questionId.includes(ele.key)) {
                    return {
                        ...ele,
                        useFlag: true
                    }
                } else {
                    return {
                        ...ele,
                        useFlag: false
                    }
                }
            })
            ]

            this.missionRewardOptions = [...this.missionRewardOptions.map((ele) => {
                if (rewardId.includes(ele.key)) {
                    return {
                        ...ele,
                        useFlag: true
                    }
                } else {
                    return {
                        ...ele,
                        useFlag: false
                    }
                }
            })
            ]
        },

        async onList(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/mission`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;

                    this.items = apiData.game_mission_items.map((ele: any) => {
                            const value = ele.items.map((obj: MissionSetForm, idx: number) => {
                                this.pinId += 1
                                return {
                                    ...obj,
                                    pinId: idx,
                                    updateFlag: false
                                }
                            })
                            return {
                                ...ele,
                                items: value
                            }
                        }
                    );


                    this.originItems = apiData.game_mission_items.map((ele: any) => {
                            const value = ele.items.map((obj: MissionSetForm, idx: number) => {
                                this.pinId += 1
                                return {
                                    ...obj,
                                    pinId: idx,
                                    updateFlag: false
                                }
                            })
                            return {
                                ...ele,
                                items: value
                            }
                        }
                    );


                    this.currentLocation = {
                        lat: apiData.channel_latitude,
                        lng: apiData.channel_longitude
                    }

                    this.quarterLength = apiData.game_mission_items.length
                    this.setting_step = apiData.setting_step

                    this.game_name = apiData.game_name
                    this.pagination = apiData.pagination;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;
                })
        },


        async onCreate(gameId: number) {
            this.formSaving = true;
            this.formLoading = true
            this.form!.latitude = Number(this.form!.latitude)
            this.form!.longitude = Number(this.form!.longitude)
            return apiRequest.post({
                url: `/api/game/${gameId}/mission/quarter/${this.query.quarter}`,
                data: this.form
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);
                    const {apiData} = data;

                    this.form!.game_mission_id = apiData.game_mission_id;

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                    this.formLoading = false

                })
        },


        async onUpdateAll(gameId: number) {


            const {gameIdItems, noneGameIdItems} : {gameIdItems: MissionSetForm[], noneGameIdItems: MissionSetForm[]} = this.items.reduce(
                (acc: {gameIdItems: MissionSetForm[], noneGameIdItems: MissionSetForm[]} , cur: any) => {
                    cur.items.forEach((ele: MissionSetForm) => {
                        if (ele.game_mission_id) {
                            acc.gameIdItems.push(ele);
                        } else {
                            acc.noneGameIdItems.push(ele);
                        }
                    });
                    return acc;
                },
                {gameIdItems: [], noneGameIdItems: []} // 초기값
            );



            const currentIds: Set<number> = new Set(gameIdItems.reduce((acc: number[], cur: any) => {
                return [...acc, cur.game_mission_id]
            }, []))

            const originIds: Set<number> = new Set(this.originItems.reduce((acc, cur) => {
                const value = cur.items.map((ele: MissionSetForm) => ele.game_mission_id)
                return [...acc, ...value]
            }, []))

            const delIds = [...originIds].filter((ele) => !currentIds.has(ele))


            this.create_items = noneGameIdItems

            console.log("=>(mission.ts:261) noneGameIdItems", noneGameIdItems);
            this.update_items = gameIdItems.filter((ele: MissionSetForm) => ele.updateFlag)
            this.delete_ids = [...delIds]

            this.formSaving = true;

            return apiRequest.put({
                url: `/api/game/${gameId}/mission/all`,
                data: {
                    create_items: this.create_items,
                    delete_ids: this.delete_ids,
                    update_items: this.update_items
                },
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },


        async onGetMissionRewardOptions(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/reward/options`,
                onError: false,

            })
                .then((data) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data;
                    this.missionRewardOptions = apiData.reward_options

                    this.missionRewardOptions = [...this.missionRewardOptions.map((ele) => {
                        return {
                            ...ele,
                            useFlag: false
                        }
                    })
                    ]

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;
                })
        },


        async onGameMissionQuestion(gameId: number): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/question/options`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.missionQuestionItems = apiData.question_options;

                    this.missionQuestionItems = [...this.missionQuestionItems.map((ele) => {
                        return {
                            ...ele,
                            useFlag: false
                        }
                    })
                    ]

                    this.changeMissionSelectItems()
                    this.formOrigin = cloneDeep(apiData.game);

                    return Promise.resolve(apiData);
                })
                .catch((error) => {

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;
                })
        },


        async onUpdateLocation(gameId: number, gameMissionId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/game/${gameId}/mission/${gameMissionId}/location`,
                data: this.form,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onDelete(gameId: number, gameMissionId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/game/${gameId}/mission/${gameMissionId}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        },

        async onDeleteAll(gameId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/game/${gameId}/mission/quarter/${this.query.quarter}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        }

    }

})
