// Composable
import {defineStore} from "pinia";

import {MAxiosAuthorizationStorageType} from "ig_module_ts";
import {setCookie, removeCookie} from "typescript-cookie";

import {PlayAuthStoreStateProps, PlayAuthStoreUserData} from "@/models";
import {apiRequest} from "@/repositories";

export const usePlayAuthStore = defineStore("playAuth", {
    state: (): PlayAuthStoreStateProps => ({
        user: null,
        game_id: null,

        // sign in & up
        signInForm: {
            id: "",
            password: "",
        },
    }),
    getters: {},
    actions: {
        // private
        _bindingUserData(src?: PlayAuthStoreUserData | null): void {
            this.user = (src === undefined || src === null) ? null : src;
        },

        _bindingToken(src?: any): void {
            const token = src?.token ?? null;
            if (token === null) {
                return;
            }

            switch (apiRequest.authorizationStorageType) {
                case MAxiosAuthorizationStorageType.LocalStorage:
                    localStorage.setItem(apiRequest.authorizationStorageKey, `Bearer ${token}`);
                    break;
                case MAxiosAuthorizationStorageType.SessionStorage:
                    sessionStorage.setItem(apiRequest.authorizationStorageKey, `Bearer ${token}`);
                    break;
                case MAxiosAuthorizationStorageType.Cookies:
                    setCookie(apiRequest.authorizationStorageKey, `Bearer ${token}`);
                    break;
            }
        },

        _removeToken(): void {
            switch (apiRequest.authorizationStorageType) {
                case MAxiosAuthorizationStorageType.LocalStorage:
                    localStorage.removeItem(apiRequest.authorizationStorageKey);
                    break;
                case MAxiosAuthorizationStorageType.SessionStorage:
                    sessionStorage.removeItem(apiRequest.authorizationStorageKey);
                    break;
                case MAxiosAuthorizationStorageType.Cookies:
                    removeCookie(apiRequest.authorizationStorageKey);
                    break;
            }
        },

        // api
        async onCheck(): Promise<void> {
            return apiRequest.get({
                url: "/api/play/check",
                onError: false,
                authorization: true,
            }).then((data) => {
                this._bindingUserData(data.apiData);

                console.log("=>(playAuth.ts:71) ", );
                this.game_id = data.apiData.game_id;
                return Promise.resolve();
            }).catch(() => {
                this._bindingUserData(null);
                return Promise.reject();
            });
        },

        async onSignIn(): Promise<any> {
            return apiRequest.post({
                url: "/api/play/sign-in",
                data: {
                    id: this.signInForm.id,
                    password: this.signInForm.password
                },
            })
                .then(({apiData}) => {
                    this._bindingToken(apiData);
                })
                .catch((err) => {
                    this._removeToken();
                    return Promise.reject(err);
                })
        },

        async logout(): Promise<void> {
            this._removeToken();
            return Promise.resolve();
        },

        initForm() {
            this.signInForm = {
                id: "",
                password: "",
            }
        },

        // functions
        getUserData(keyName: string): string | number | boolean | null {
            return (this.user === null) ? null : (this.user[keyName] ?? null)
        }
    }
});
