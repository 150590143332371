import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import dayjs from "dayjs";
import {GameStoreStateProps} from "@/models";

export const useGameStore = defineStore("game", {
    state: (): GameStoreStateProps => ({
        items: [],
        query: {
            page: 1,
            perPage: 50,
            name: '',
            statusType: 0
        },

        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 50,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        logoValid: false,
        backgroundValid: false,
        game_id: 0,

        copyForm: {
            begin_at: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            end_at: dayjs(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD HH:mm:ss'),
            id: "",
            name: "",
            play_password: '',
            team_member_limit: 1
        },

        copyGameName: '',
    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                admin_id: null,
                begin_at: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                end_at: dayjs(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD HH:mm:ss'),
                channel_id: null,
                description: "",
                game_reward_id: null,
                name: "",
                question_set_id: null,
                reward_set_id: null,
                setting_step: 1,
                ending_question_id: null,
                intro_question_id: null,
                final_question_id: null,
                final_question_type: null,
                play_password: '',
                id: '',
                arrived_minimum_point: 0,
                arrived_decrement_point: 0,
                arrived_point: 0,
                mission_minimum_point: 0,
                mission_decrement_point: 0,
                mission_point: 100,
                team_member_limit: 1,
                icon_set_no: 1,
                previewer_flag: false,
                quarters: [
                    {process_type: 0, quarter_no: 1},
                    {process_type: 0, quarter_no: 2},
                    {process_type: 0, quarter_no: 3},
                    {process_type: 0, quarter_no: 4},
                ],
            };
            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },
        async onList(): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game`,
                onError: false,
                params: this.query,
            })
                .then((data) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data;
                    this.items = apiData.game_items;
                    this.pagination = apiData.pagination;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;
                })
        },

        async onCreate() {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/game`,
                data: this.form
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGet(gameId: number): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.form = apiData.game;

                    this.formOrigin = cloneDeep(apiData.game);

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    this.form = null;
                    this.formOrigin = null;

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;
                })
        },

        async onUpdate(gameId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/game/${gameId}`,
                data: this.form,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {

                    this.formSaving = false;
                })
        },

        async onCopy(gameId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/game/${gameId}/copy`,
                data: this.copyForm
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);





                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {

                    this.formSaving = false;
                })
        },

        async onDelete(gameId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/game/${gameId}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        }

    }

})
